import { useQuery } from '@tanstack/vue-query';
import type { Database } from '~/types/Supabase';

export type AdminPrivilegesSearchParams = {
	userId: MaybeRef<string>;
};

export const useAdminPrivilegesQuery = (
	options: AdminPrivilegesSearchParams,
) => {
	const client = useSupabaseClient<Database>();

	const query = useQuery({
		queryKey: ['admin-campaigns', options.userId],
		queryFn: async () => {
			const { data } = await client
				.from('admins_in_merchants')
				.select('*')
				.eq('userId', unref(options.userId));

			return data ?? [];
		},
	});

	return query;
};
